import React from 'react';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';
import kidsImg from '../../../static/tips/tips-kids.jpg';

const Contact = props => (
  <Layout bodyClass="page-tips">
    <SEO title="Tips" />
    <div className="intro">
      <div className="container">
        <div className="row d-flex justify-content-center">
          <div className='col-lg-4 col-3'></div>
            <div className="col-lg-8 col-9">
            <h1>Tips</h1>
            <p>Here are some tips and hints to help get you started with I Can Spell My Colors eBook, musical album, and activities!</p>
          </div>
        </div>
      </div>
    </div>
    <div className='music-notes-background'>
      <div className='container pt-6 pb-6'>
        <div className='row d-flex justify-content-center'>
          <div className='d-flex col-lg-6 col-md-8 col-sm-12 p-2 p-sm-0 pb-md-2 pb-sm-2 pb-lg-5 p-xl-0 justify-content-center'>
            <img className='tips-img' src={kidsImg} alt='kids' data-pin-nopin="true"/>
          </div>
          <div className='d-flex flex-column col-lg-6 col-md-12 col-sm-12 p-4 p-sm-0 p-md-0 p-lg-0 p-xl-0 justify-content-center'>
            <p>
              Research has proven the effect and benefits of music to children during their early childhood
              development, especially when combined with singing, dancing, clapping and counting. These benefits
              include language, cognitive, physical and social/emotional development. 
            </p>
            <p>
              Teaching through music provides a fun and exciting approach to learning. It also plays a powerful role 
              in establishing fine and gross motor skills, as well as reading and math readiness, auditory 
              discrimination, brain and body coordination, learning new words and concepts, memory retention, 
              developing self-confidence and much more.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div style={{backgroundColor: '#d2ecef'}}>
      <div className='container pt-2 pb-6'>
        <h2 style={{fontWeight: 700}}>Ways to use this book and music</h2>
        <div className='row'>
          <div className='col-12 col-lg-6 col-xl-6'>
            <h4 style={{fontWeight: 700}}>Teachers</h4>
            <ul>
              <li>Add props and this music makes a fun school program! Play either the vocal or instrumental version of the music to accompany the children.</li>
              <li>Listen to music while transitioning from one subject to another.</li>
              <li>Encourage children to sing along during clean-up time.</li>
              <li>Choose a child to be the chorister. Pay attention class! Will the chorister have you sing slowly and hold a note, or really fast?</li>
              <li>Perfect for “Inside Days”. Put the music on and let the children sing and dance to relieve their wiggles. Kids love to FREEZE dance!</li>
              <li>Give them instruments and have a marching band.</li>
              <li>Clap in rhythm with the music.</li>
              <li>If balls are available, bounce the ball and sing.</li>
              <li>Need some holiday music? Halloween: <strong>WHITE</strong>; Christmas: <strong>BROWN</strong>; St. Patrick’s Day: <strong>GREEN</strong>.</li>
              <li>At the end of the <strong>WHITE</strong> song when children sing “BOO!” pull out a handkerchief ghost on a string and fly it over the kids' heads.</li>
              <li>While playing the <strong>GRAY</strong> song, have children tip-toe in a circle and sing quietly so as not to scare the mouse.</li>
              <li>Play the <strong>BLACK</strong> song and allow the boys to dance and be ninjas while the girls watch. Then switch.</li>
              <li>Choose a King and Queen to stand in the middle. The other children hold hands forming a circle around them and sing the <strong>PINK</strong> song.</li>
              <li>How does this song make you feel? (<strong>GREEN</strong>: happy because a 4-leaf clover is good luck) (<strong>ORANGE</strong>: frustrated you can’t reach the fruit) (<strong>PURPLE</strong>: scared the skunk might spray) (<strong>PINK</strong>: excited for a special day and treat) (<strong>BLACK</strong>: powerful like a superhero) (<strong>BROWN</strong>: magical because Rudolph can fly) (<strong>REPRISE</strong>: smart and proud because you know how to spell your colors!)</li>
              <li>Color Spelling Bee</li>
              <li>Musical Chairs</li>
              <li>Spell the color of shirt/slacks/dress they are wearing.</li>
              <li>Check out all the free printable activities.</li>
            </ul>
          </div>
          <div className='col-12 col-lg-6 col-xl-6'>
            <h4 style={{fontWeight: 700}}>Parents</h4>
            <ul>
              <li>Listen in the car, or sing a cappella at bedtime.</li>
              <li>Play a game with the free printable activities available on this site.
                <ul>
                  <li>Flash Cards</li>
                  <li>Color Cube Dice</li>
                  <li>Color Wheel</li>
                  <li>Color Bingo</li>
                  <li>Journey to the End of the Rainbow board game</li>
                  <li>Coloring Pages</li>
                </ul>
              </li>
              <li>Spell the color of shirt/slacks/dress they are wearing.</li>
              <li>Children who play an instrument may use the sheet music in the back of the eBook to play the color songs.</li>
              <li>Stream the music while children play.</li>
              <li>Sing songs while doing chores.</li>
              <li>Let your child lead the music to one of the color songs. Go fast, or go slow. Hold a note!</li>
              <li>Sing a color song to the child and leave out a letter or word and let them fill it in.</li>
              <li>Use your imagination! (<strong>ORANGE</strong>: reach up high, grab an orange and taste it) (<strong>BLUE</strong>: walk alongside the ocean in the sand and pick up sea shells. What did you find?) (<strong>RED</strong>: 911! Let’s help spray out the fire. First we must put on our fireman gear.)</li>
              <li>Put all the color words into a jar and pull one out. That is the song you must sing.</li>
              <li>Impromptu dancing to the music; stop suddenly and “FREEZE!”</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default Contact;
